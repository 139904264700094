
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Footer from '../site/footer';
import content from '../content/content';


function Business() {

	const navigate = useNavigate();
	const location = useLocation();
	const hash = location.hash;

	const fadeAbout = hash && hash !== "#us" ? "fade" : "";
	const fadeContact = hash && hash !== "#contact" ? "fade" : "";
	const fadePrivacy = hash && hash !== "#privacy" ? "fade" : "";

    const appName = content.app.name;
	const root = appName.toLowerCase();
	const contactEmail = content.contact.email;

	function clearHash() {
		if (hash !== '') {
			navigate(location.pathname);
		}
	}

	return (
		<div className="page-wrapper business-info" onClick={e => clearHash()}>
			<header>
				<h1>DingyWorks</h1>
			</header>
			<main>
				<section className={fadeAbout}>
					<h2>About</h2>
					<p>
						Established in 2021 with a goal of producing entertaining software that stimulates creativity.
						Currently under development is a virtual building system
						designed to give the feel of traditional wood beam construction, in the confines of a hand-held device.
					</p>
					<p>
						Code-named {appName}, you can learn more about this upcoming product at the link below:
					</p>
					<p></p>
					<ul>
						<Link className="button-link" to={`/${root}`} onClick={e => e.stopPropagation()}><li>{appName} Home Page</li></Link>
						{/* <Link className="button-link" to={`/apple-store`} onClick={e => e.stopPropagation()}><li>{appName} in Apple App Store</li></Link> */}
						{/* <Link className="button-link" to={`/play-store`} onClick={e => e.stopPropagation()}><li>{appName} in Google Play Store</li></Link> */}
					</ul>
				</section>
				<section id="contact" className={fadeContact}>
					<h2>Contact</h2>
					<p>
						Send feedback and inquiries to <a href={`mailto:${contactEmail}`} onClick={e => e.stopPropagation()}>{contactEmail}</a>
					</p>
				</section>
				<section className={fadePrivacy}>
					<h2>Privacy</h2>
					<p>
						We respect your privacy. Please see our <Link to={`/privacy`}>privacy policy</Link> for details.
					</p>
				</section>
			</main>

			<Footer />
		</div>
	);
}

export default Business;
