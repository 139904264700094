
import React from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import PageContent from '../site/page-content';
import content from '../content/content';


function IdeaDetail(props) {

	const { idea } = useParams();

	const sample = content.ideas.samples.filter(s => s.title.toLowerCase() === idea)[0] || {};

	const { title, titleImage, description } = sample;

	const [ selection, setSelection ] = useState(titleImage);

	return (
		<PageContent name="idea-detail-page" flush>
			<div className="idea-detail-title">
				<h3>{title}</h3>
			</div>
			<div className="idea-detail-desc">
				<p>{description}</p>
			</div>

			<div className="idea-detail-slides">
				{sample.thumbnails.map((t,i) =>
					<picture key={i}>
						<source
							srcSet={`${t}-512.avif 512w, ${t}-1024.avif 1024w, ${t}.avif 2208w`}
							sizes="(max-width: 784px) 100vw, 184px"
							type="image/avif"
						/>
						<source
							srcSet={`${t}-512.webp 512w, ${t}-1024.webp 1024w, ${t}.webp 2208w`}
							sizes="(max-width: 784px) 100vw, 184px"
							type="image/webp"
						/>
						<img
							className="idea-detail-image"
							srcSet={`${t}-512.jpeg 512w, ${t}-1024.jpeg 1024w, ${t}.jpeg 2208w`}
							sizes="(max-width: 784px) 100vw, 184px"
							src={`${t}.jpeg`}
							alt={`Slide ${i}`}
						/>
					</picture>
				)}
			</div>


			<div className="idea-detail-container">
				<picture>
					<source
						srcSet={`${selection}-512.avif 512w, ${selection}-1024.avif 1024w, ${selection}.avif 2208w`}
						sizes="(max-width: 784px) 100vw, 784px"
						type="image/avif"
					/>
					<source
						srcSet={`${selection}-512.webp 512w, ${selection}-1024.webp 1024w, ${selection}.webp 2208w`}
						sizes="(max-width: 784px) 100vw, 784px"
						type="image/webp"
					/>
					<img
						className="idea-detail-image"
						srcSet={`${selection}-512.jpeg 512w, ${selection}-1024.jpeg 1024w, ${selection}.jpeg 2208w`}
						sizes="(max-width: 784px) 100vw, 784px"
						src={`${selection}.jpeg`}
						alt={`${title} under construction`}
					/>
				</picture>
				<div className="idea-detail-lower-container">
					<div className="idea-detail-lower">
						{sample.thumbnails.map((t,i) =>
							<div key={i} className="idea-detail-thumbnail-container" onClick={() => setSelection(t)} >
								<picture>
									<source
										srcSet={`${t}-512.avif 512w, ${t}-1024.avif 1024w, ${t}.avif 2208w`}
										sizes="(max-width: 784px) 100vw, 184px"
										type="image/avif"
									/>
									<source
										srcSet={`${t}-512.webp 512w, ${t}-1024.webp 1024w, ${t}.webp 2208w`}
										sizes="(max-width: 784px) 100vw, 184px"
										type="image/webp"
									/>
									<img
										className="idea-detail-thumbnail"
										srcSet={`${t}-512.jpeg 512w, ${t}-1024.jpeg 1024w, ${t}.jpeg 2208w`}
										sizes="(max-width: 784px) 100vw, 184px"
										src={`${t}.jpeg`}
										alt={`Slide ${i}`}
									/>
								</picture>
							</div>
						)}
					</div>
				</div>
			</div>

		</PageContent>
	);
}

export default IdeaDetail;
