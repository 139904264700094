
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import NotFound from './site/not-found';
import PageLayout from './site/page-layout';
import Business from './business/business';
import Privacy from './business/privacy';
import Home from './home/home';
import Guide from './guide/guide';
import Ideas from './ideas/ideas';
import IdeaDetail from './ideas/idea-detail';
import Support from './support/support';
import content from './content/content';


function Redirect(props) {
	return <Navigate to={props.to} replace />;
}


class App extends React.Component {

	render() {
		const root = content.app.name.toLowerCase();

		return (
			<Routes>
				<Route path="/" element={<Redirect to="/about" />}></Route>

				{/* documents served raw without site formatting */}
				<Route path="/about" element={<Business />} />
				<Route path="/privacy" element={<Privacy />} />

				{/* wrapped in site component */}
				<Route path={`/${root}`} element={<PageLayout />}>
					<Route path={`/${root}`} element={<Home />} />
					<Route path={`/${root}/guide`} element={<Guide />} />
					<Route path={`/${root}/ideas/:idea`} element={<IdeaDetail />} />
					<Route path={`/${root}/ideas`} element={<Ideas />} />
					<Route path={`/${root}/support`} element={<Support />} />
				</Route>

				<Route path="*" element={<NotFound />} />
			</Routes>
		);
	}
}

export default App;
