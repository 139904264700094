
import Section from './section';
import PageContent from '../site/page-content';
import content from '../content/content';


function Guide() {

	return (
		<PageContent flush>
			{content.guide.map(({title, topics, content, expand}) =>
				<Section key={`${title}-section`} title={title} content={content} topics={topics} expand={!!expand} />
			)}
		</PageContent>
	);
}

export default Guide;
