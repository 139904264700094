
import React from 'react';
import { Outlet } from 'react-router';
import NavBar from './nav-bar';
import Header from './header';
import Footer from './footer';


class PageLayout extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			theme: 'reliable',
		};
	}

	setTheme(theme) {
		this.setState({ theme: theme });
	}


	render() {
		return (
			<div className={`page-wrapper ${this.state.theme}`}>
				<NavBar theme={this.state.theme} onThemeChange={t => this.setTheme(t)} />
				<Header />
				<main>
					<Outlet />
				</main>
				<Footer />
			</div>
		);
	}
}


export default PageLayout;
