
import PageContent from './page-content';
import content from '../content/content';

function Header() {
	const appName = content.app.name;

	return (
		<PageContent>
			<header>
				<h1>{appName}</h1>
			</header>
		</PageContent>
	);
}

export default Header;
