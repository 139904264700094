
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Footer from '../site/footer';
import content from '../content/content';


function Privacy() {
    const appName = content.app.name;
	const contactEmail = content.contact.email;

	return (
		<div className="page-wrapper privacy-policy">
			<main>
				<h2>Privacy Policy</h2>

				<p>Last updated: June 8, 2022</p>

				<p>
					This privacy policy describes the data collected from you by DingyWorks, LLC ("we", "us", or "our"),
					through the www.dingyworks.com website ("the website") or the {appName} mobile app ("the app"), and
					the manner in which we use that data.
				</p>


				<h3>What types of information do we collect</h3>
				<ul>
					<li>Crash reports, energy reports, and anonymous usage metrics</li>
					<li>Contact information</li>
					<li>User content</li>
				</ul>

				<h3>How do we collect information</h3>
				<h5>From you</h5>
				<p>
					We collect your name and email address when you send an email to our contact or support email
					address.
				</p>
				<p>
					We collect content you have created within the app if you choose to attach save files from the
					app to any email that you send us. We do not maintain save files on our servers, or automatically
					send any content you create to our servers.
				</p>
				<h5>From Apple</h5>
				<p>
					We may receive crash reports, energy reports, and metrics related to your use of the app from
					Apple. This data cannot be used to personally identify you. You may limit the collection of
					this data by disabling the "Share With App Developers" privacy setting on your device.
				</p>


				<h3>How do we use the information we collect</h3>
				<p>
					We use any contact information and user content you provide to communicate with you and respond
					to your inquiries, complaints, or other feedback.
				</p>
				<p>
					We use technical information such as crash reports to fix bugs and improve our app or website.
				</p>
				<p>
					We do not use cookies or track your use of the website.
				</p>
				<p>
					We do not sell personal data.
				</p>


				<h3>Childrens Privacy</h3>
				<p>
					We do not knowingly collect personal information from children under the age of 16. If we become
					aware that a child under the age of 16 has provided us with personal information, we will remove
					that information from our records.
				</p>


				<h3>Changes to this policy</h3>
				<p>
					We may occasionally make changes to this policy. If we do make changes, we will notify you by
					revising the date at the top of this policy, so we encourage you to check back periodically to
					stay informed.
				</p>

				<h3>Contact</h3>
				<p>If you have any questions or comments about this policy, you may send them to us at {contactEmail}</p>
				<p></p>
			</main>

			<Footer />
		</div>
	);
}

export default Privacy;
