
import { Link } from 'react-router-dom';
import Slideshow from './slideshow';
import PageContent from '../site/page-content';
import content from '../content/content';


const images = [
	"cottage-1",
	"cottage-2",
	"cottage-3",
	"cottage-4",
	"cottage-5",
	"cottage-6",
];


function Home() {
	const root = content.app.name.toLowerCase();

	return (
		<PageContent name="home-page">
			<Slideshow images={images} />

			<section>
				{ content.home.map((str,index) => <p key={index}>{str}</p>) }
			</section>

			<ul>
				<Link className="button-link" to={`/${root}/guide`}><li>Guide</li></Link>
				<Link className="button-link" to={`/${root}/ideas`}><li>Ideas</li></Link>
			</ul>
		</PageContent>
	);
}

export default Home;
