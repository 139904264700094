
import React from 'react';


class Slideshow extends React.Component {

	constructor(props) {
		super(props);
		this.last = props.images.length - 1;
		this.final = props.images[this.last];
		this.all = [...props.images.keys()];
		this.state = {
			fade: this.last,
			active: this.last,
			slides: []
		};
	}

	componentWillUnmount() {
		clearTimeout(this.timer);
	}

	loadSlides() {
		this.setState({
			slides: this.props.images
		});
	}

	onImageLoaded(index) {
		this.all = this.all.filter(i => i !== index);
		if (this.all.length === 0) {
			this.advance();
		}
	}

	advance() {
		let showTime = 2000;  // css transition time for showing image
		let hideTime = 50;    // css transition time for hiding image
		let safeTime = 50;    // extra time given to ensure css transitions complete
		let holdTime = 2000;  // time to hold each frame in place between transitions
		let restTime = 3000;  // time to wait before starting or restarting slideshow

		// Must hold/rest for at least long enough to switch previous frame image to background
		holdTime = Math.max(holdTime, hideTime + safeTime);
		restTime = Math.max(restTime, hideTime + safeTime);

		this.setState({ fade: this.state.active });

		let next = (this.state.active + 1) % this.props.images.length;
		let delay = next > 0 ? holdTime : restTime;
		this.timer = setTimeout(() => {

			this.setState({ active: next });

			this.timer = setTimeout(() => {
				this.advance();
			}, showTime + safeTime);
		}, delay);
	}

	render() {

		if (this.props.images.length < 1) {
			return (
				<p className="error">No slideshow images were supplied.</p>
			);
		}


		return (
			<div className="slideshow-outer">
				<picture>
					<source
						srcSet={`/${this.final}-512.avif 512w, /${this.final}-1024.avif 1024w, /${this.final}.avif 2208w`}
						sizes="(max-width: 784px) 100vw, 784px"
						type="image/avif"
					/>
					<source
						srcSet={`/${this.final}-512.webp 512w, /${this.final}-1024.webp 1024w, /${this.final}.webp 2208w`}
						sizes="(max-width: 784px) 100vw, 784px"
						type="image/webp"
					/>
					<img
						className={`${this.state.fade === this.last ? 'fade' : ''}`}
						srcSet={`/${this.final}-512.jpeg 512w, /${this.final}-1024.jpeg 1024w, /${this.final}.jpeg 2208w`}
						sizes="(max-width: 784px) 100vw, 784px"
						src={`/${this.final}.jpeg`}
						alt="Cottage"
						onLoad={() => this.loadSlides()}
					/>
				</picture>

				{this.state.slides.map((s,i) =>
					<picture key={i}>
						<source
							srcSet={`/${s}-512.avif 512w, /${s}-1024.avif 1024w, /${s}.avif 2208w`}
							sizes="(max-width: 784px) 100vw, 784px"
							type="image/avif"
						/>
						<source
							srcSet={`/${s}-512.webp 512w, /${s}-1024.webp 1024w, /${s}.webp 2208w`}
							sizes="(max-width: 784px) 100vw, 784px"
							type="image/webp"
						/>
						<img
							className={`${this.state.fade === i && i < this.last ? 'fade' : ''} ${this.state.active === i ? 'active' : ''}`}
							srcSet={`/${s}-512.jpeg 512w, /${s}-1024.jpeg 1024w, /${s}.jpeg 2208w`}
							sizes="(max-width: 784px) 100vw, 784px"
							src={`/${s}.jpeg`}
							alt={`Cottage under construction - slide ${i}`}
							onLoad={() => this.onImageLoaded(i)}
						/>
					</picture>
				)}

			</div>
		);
	}
}

export default Slideshow;
