
import React from 'react';
import { Link } from 'react-router-dom';
import content from '../content/content';


class NavBar extends React.Component {

	toggleStyle() {
		const styles = ['earnest','playful','reliable'];
		var index = styles.indexOf(this.props.theme);
		const theme = styles[(index + 1) % styles.length];
		this.props.onThemeChange(theme);
	}

	render() {

		const root = content.app.name.toLowerCase();
		const theme = this.props.theme;
		const lable = theme.charAt(0).toUpperCase() + theme.slice(1);

		return (
			<div>
				<nav className="navbar">
					<ul>
					<li className="split"></li>
						<Link className="nav-link" to={`/${root}`}><li>Home</li></Link>
						<li className="split"></li>
						<Link className="nav-link" to={`/${root}/guide`}><li>Guide</li></Link>
						<li className="split"></li>
						<Link className="nav-link" to={`/${root}/ideas`}><li>Ideas</li></Link>
						<li className="split"></li>
						<Link className="nav-link" to={`/${root}/support`}><li>Support</li></Link>
{/*
						<li className="split"></li>
						<li className="nav-link" onClick={e => this.toggleStyle(e)}>{lable}</li>
*/}
						<li className="split"></li>
					</ul>
				</nav>
			</div>
		);
	}
}

export default NavBar;
