
function Topic(props) {
	let paragraphs = [].concat(props.content);

	return (
		<div className="topic">
			<div className="topic-title guide-title">
				<span className="topic-title-text">{props.title}</span>
			</div>
			<div className="topic-content-outer">
				<div className="topic-content">
					{paragraphs.map((paragraph,index) =>
						<p key={index}>{paragraph}</p>
					)}
				</div>
			</div>
		</div>
	);
}

export default Topic;
