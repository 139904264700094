
import { Link } from 'react-router-dom';
import PageContent from '../site/page-content';
import content from '../content/content';


function Support() {
	const root = content.app.name.toLowerCase();
	const contactEmail = content.contact.email;

	return (
		<PageContent name="support-page">

			<main>
				<section>
					<h2>How may we help you?</h2>
					<h3>Report a problem</h3>
					<p>
						If you have encountered a bug or other problem with our app, please send us an email and let us
						know about it at <a href={`mailto:${contactEmail}`} onClick={e => e.stopPropagation()}>{contactEmail}</a>.
						Feedback and other inquiries are welcome as well.
					</p>
					<h3>Documentation</h3>
					<p>
						If you need help using the app, please see our <Link to={`/${root}/guide`}>guide</Link> for
						information about features, available construction elements, and rules for building.
					</p>
					<h3>Other information</h3>
					<ul>
						<Link to={`/about`}><li>About Us</li></Link>
						<Link to={`/privacy`}><li>Privacy Policy</li></Link>
					</ul>
				</section>
			</main>
		</PageContent>
	);
}

export default Support;
