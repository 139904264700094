
import React from 'react';


function PageContent(props) {
	return (
		<div className={`page-content-outer ${props.name || ''} ${props.flush ? 'flush' : ''}`}>
			<div className="page-content-inner">
				{props.children}
			</div>
		</div>
	);
}


export default PageContent;
