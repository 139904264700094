
import content from './content.yaml';


function replaceParameters(value) {
	if (typeof value === 'string') {
		return value.replace(/~app\.name~/g, content.app.name);
	}
	else if (Array.isArray(value)) {
		return value.map(e => replaceParameters(e));
	}
	else if (value && typeof value === 'object') {
		var obj = {};
		for (var key in value) {
			obj[key] = replaceParameters(value[key]);
		}
		return obj;
	}
	return value;
}

const processed = replaceParameters(content);

export default processed;
