
import IdeaSummary from './idea-summary';
import PageContent from '../site/page-content';
import content from '../content/content';


function Ideas() {

	return (
		<PageContent flush>
			<div className="ideas-desc">{content.ideas.summary}</div>

			<div className="idea-title-desc-separator" />

			{content.ideas.samples.map(({ title, description, titleImage }) =>
				<IdeaSummary key={`idea-${title}`} ideaTitle={title} ideaDesc={description} titleImg={titleImage} />
			)}
		</PageContent>
	);
}

export default Ideas;
