
import React from 'react';
import Topic from './topic';


class Section extends React.Component {

	constructor(props) {
		super(props);

		this.ref = React.createRef();

		this.expand = props.expand;
		this.state = {
			expand: this.expand,
		};
	}

	componentDidMount() {
		if (this.props.expand)
			this.ref.current.style.height = 'auto';
	}

	collapseSection() {
		const element = this.ref.current;
		const transition = element.style.transition;

		element.style.transition = '';

		requestAnimationFrame(() => {
			element.style.height = element.scrollHeight + 'px';
			element.style.transition = transition;

			requestAnimationFrame(() => {
				element.style.height = null;
			});
		});
	}

	expandSection() {
		const element = this.ref.current;
		element.style.height = element.scrollHeight + 'px';
	}

	onTransitionEnd() {
		if (this.expand) {
			this.ref.current.style.height = 'auto';
		}
	}

	toggleExpand() {
		this.expand = !this.expand;
		if (this.expand) {
			this.expandSection();
		}
		else {
			this.collapseSection();
		}

		this.setState({
			expand: this.expand
		});
	}

   render() {

		let paragraphs = this.props.content ? [this.props.content] : [];
		let topics = this.props.topics || [];

		return (
			<div className="section-container">
				<div className="section-title guide-title" onClick={() => this.toggleExpand()}>
					<span className="section-title-text">{this.props.title}</span>
					<span className="unselectable">{this.state.expand ? '-' : '+'}</span>
				</div>

				<div ref={this.ref} className="section-content-outer" onTransitionEnd={() => this.onTransitionEnd()}>
					<div className="section-content">
						{paragraphs.map((p,i) =>
							<p key={i}>{p}</p>
						)}
					</div>
					{topics.map((t,i) =>
						<Topic key={i} title={t.title} content={t.content} />
					)}
				</div>
			</div>
		);
	}
}

export default Section;
