
import React from "react";
import { useNavigate } from 'react-router-dom';
//import { navigate } from 'react-router-dom';
import content from '../content/content';


function withNavigate(Component) {
	return function WrappedComponent(props) {
		const navigate = useNavigate();
		return <Component {...props} navigate={navigate} />
	}
}

class IdeaSummary extends React.Component {

	constructor(props) {
		super(props);

		this.ref = React.createRef();

		this.expand = false;
		this.state = {
			expand: this.expand,
//			titleImg: this.props.titleImg,
		};
	}

	collapseIdea() {
		const element = this.ref.current;
		const ideaHeight = element.scrollHeight;

		requestAnimationFrame(() => {
			element.style.height = ideaHeight + 'px';

			requestAnimationFrame(() => {
				element.style.height = 0 + 'px';
			});
		});

		// Also reset title image on collapse
		this.setState({
			titleImg: this.props.titleImg,
		});
	}

	expandIdea() {
		const element = this.ref.current;
		element.style.height = element.scrollHeight + 'px';
	}

	onTransitionEnd() {
		if (this.expand) {
			this.ref.current.style.height = 'auto';
		}
	}

	toggleExpand() {
		// this.expand = !this.expand;
		// if (this.expand) {
		// 	this.expandIdea();
		// }
		// else {
		// 	this.collapseIdea();
		// }

		// this.setState({
		// 	expand: this.expand
		// });

		const appName = content.app.name;
		const root = appName.toLowerCase();

//		const navigate = useNavigate();
		const idea = this.props.ideaTitle.toLowerCase();
		this.props.navigate(`/${root}/ideas/${idea}`);
	}

	changeTitleImage(event, image) {
		event.stopPropagation();
		
		this.setState({
			titleImg: image,
		});
	}

	render() {
//		let images = this.props.thumbnails;
//		let thumbnails = [];

		// for (let i = 0; i < images.length; i++) {
		// 	let source = images[i];
			
		// 	thumbnails.push(
		// 		<div
		// 			key={`${this.props.ideaTitle}-thumbnail-${i}`}
		// 			className="idea-thumbnail-container"
		// 			onClick={e => this.changeTitleImage(e, source)}
		// 		>
		// 			<img
		// 				src={`${source}-thumb.png`}
		// 				className="idea-thumbnail"
		// 				alt={`${this.props.ideaTitle} thumbnail ${i}`}
		// 			/>
		// 		</div>
		// 	)
		// }

		const props = this.props;
		return (
			<div className="idea-container" onClick={() => this.toggleExpand()}>
				<div className="idea-upper-container">

					<div className="idea-text-container">
						<div className="idea-title">
							<div>{props.ideaTitle}</div>
							<div className="unselectable">&rarr;</div>
						</div>

						<div className="idea-desc">
							<p>{props.ideaDesc}</p>
						</div>
					</div>
					<div className="idea-image-container" >
						<picture>
							<source
								srcSet={`${props.titleImg}-512.avif 512w, ${props.titleImg}-1024.avif 1024w, ${props.titleImg}.avif 2208w`}
								sizes="(max-width: 627px) 100vw, 388px"
								type="image/avif"
							/>
							<source
								srcSet={`${props.titleImg}-512.webp 512w, ${props.titleImg}-1024.webp 1024w, ${props.titleImg}.webp 2208w`}
								sizes="(max-width: 627px) 100vw, 388px"
								type="image/webp"
							/>
							<img
								className="idea-image"
								srcSet={`${props.titleImg}-512.jpeg 512w, ${props.titleImg}-1024.jpeg 1024w, ${props.titleImg}.jpeg 2208w`}
								sizes="(max-width: 627px) 100vw, 388px"
								src={`${props.titleImg}.jpeg`}
								alt={`${props.ideaTitle}`}
							/>
						</picture>
					</div>
					<div className="idea-followup-container">
						<div className="idea-desc">
							<p>{props.ideaDesc}</p>
						</div>
					</div>
				</div>
				{/* <div ref={this.ref} className="idea-lower-container" onTransitionEnd={() => this.onTransitionEnd()} >
					<div className="idea-lower">{thumbnails}</div>
				</div> */}

				<div className="idea-title-desc-separator" />
			</div>
		)
	}
}

//export default IdeaSummary;
export default withNavigate(IdeaSummary);
