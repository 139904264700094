
import { Link } from 'react-router-dom';


function Footer() {
	return (
		<footer>
			<nav>
				<ul>
					<li><Link className="footer-link" to="/about#contact">Contact</Link></li>
					<li><Link className="footer-link" to="/privacy">Privacy</Link></li>
					<li><span className="footer-note"><span className="avoidwrap">Copyright &copy; 2021</span> <span className="avoidwrap">DingyWorks, LLC.</span> <span className="avoidwrap">All rights reserved.</span></span></li>
				</ul>
			</nav>
		</footer>
	);
}

export default Footer;
