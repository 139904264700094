
import React from 'react';
import { Link } from 'react-router-dom';
import Footer from './footer';
import content from '../content/content';


class NotFound extends React.Component {

	componentDidMount() {
		var robots = document.querySelector('meta[name="robots"]');
		if (!robots) {
			robots = document.createElement('meta');
			robots.setAttribute('name', 'robots');
			document.head.appendChild(robots);
		}
		robots.setAttribute('content', 'noindex');
	}

	componentWillUnmount() {
		var robots = document.querySelector('meta[name="robots"]');
		if (robots) {
			robots.remove();
		}
	}

	render() {
		 const appName = content.app.name;
		 const root = appName.toLowerCase();

		return (
			<div className="page-wrapper">
				<header>
					<h1>Page Not Found</h1>
				</header>
				<main class="error-info">
					<p>
						Return to the main page: <Link to={`/${root}`} onClick={e => e.stopPropagation()}>{appName}</Link>
					</p>
				</main>
				<Footer />
			</div>
		);
	}
}

export default NotFound;
